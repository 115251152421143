@import "../../styles/bootstrap-overrides";

.profileWrapper {
  height: 30px;
}

.profileImage {
  @extend .h-100;
}

.profileName {
  @extend .pl-2, .text-light;
}
