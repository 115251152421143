@import "../../../styles/bootstrap-overrides";

.trophyImage {
  padding: unset;
  margin: unset;

  @extend .mr-2;

  max-width: 4vh;
  object-fit: cover;
}
