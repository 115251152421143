@import "../../styles/bootstrap-overrides";

.footer {
  @extend .w-100, .pt-2, .pb-2;
  font-size: 0.8em;
  max-height: 10vh;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: lighten($body-bg, 2);
}
