@import "../../styles/bootstrap-overrides";
@import "../../styles/vars";

/* this is to keep you loading wrapper on the middle of screen */
.pulseWrapper {
  @extend .p-4;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  z-index: $zindex-popover;
}

.pulse {
  height: 128px;
  background-color: rgb(0, 0, 0);
  border-radius: 40%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  40% {
    transform: scale(1.1);
    box-shadow: 0 0 0 20px rgba(darken($primary, 50%), 0.3);
  }

  80% {
    transform: scale(1);
    box-shadow: 0 0 0 40px darken($primary, 25%);
  }

  100% {
    box-shadow: 0 0 0 0 darken($primary, 0%)
  }
}

.children {
  padding-top: 55px;
}
