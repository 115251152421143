@import "../../styles/bootstrap-overrides";

.header {
  @extend .text-left;
}

.logo {
  @extend .d-inline-block, .align-top, .rounded;

  height: 30px;
}

.navLink,
.navDropDownItem {
  @extend .text-capitalize;
}
