@import "./vars";
@import "~bootstrap/scss/bootstrap";

$body-bg: #282c34;

.form-group {
  @extend .pb-2;
}

.list-group-item[href] {
  cursor: pointer;

  &:hover {
    filter: brightness(115%);
  }
}

.sticky-top {
  background-color: $body-bg;
}

.header {
  z-index: $zindex-sticky + 1;

  .dropdown-menu {
    z-index: $zindex-sticky + 1;
  }
}

.jumbotron {
  @extend .text-left, .pt-4, .pb-4, .bg-primary, .text-light, .rounded-0;
}

.container {
  @extend .text-left;
}

.table {
  td {
    @extend .align-middle;
  }
}

.modal-dialog {
  @extend .border-dark, .rounded, .overflow-hidden;

  .modal-content {
    @extend .bg-dark, .border-dark, .rounded-0;

    .modal-header {
      @extend .bg-dark, .border-dark, .rounded-0;
    }

    .modal-body {
      @extend .bg-dark, .border-dark, .rounded-0;
    }

    .modal-footer {
      @extend .bg-dark, .border-dark, .rounded-0;
    }
  }
}
